import React from 'react';
import './styles.css';
import NavbarComponent from './Components/Navbar';
import CarouselComponent from './Components/Carousel';
import ContentComponent from './Components/Content';
import 'bootstrap/dist/css/bootstrap.css';
import FooterComponent from './Components/footer';

class HomeComponent extends React.Component {
	render() {
		return (
			<div className="HomeComponent">
				<NavbarComponent />
				<CarouselComponent />
				<ContentComponent />
				<FooterComponent />
			</div>
		);
	}
}

export default HomeComponent;
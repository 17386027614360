import React from 'react';

import '../styles.css';
import 'bootstrap/dist/css/bootstrap.css';
import NavbarComponent from './Navbar';
import FooterComponent from './footer';

import EmailFormComponent from './EmailForm';
import { Row, Col, Container, Card } from 'react-bootstrap';

class SpecialistFreightComponent extends React.Component {
	render() {
		return (
			<div className="SpecialistFreightComponent">
				<NavbarComponent />
				<Container>
					<Row xs={1} md={2} className="g-4 my-5">
						<Col>
							<Card
								text="light"
								className="text-center p-3 border-0 rounded"
								style={{ backgroundColor: '#2C3180' }}
							>
								<Card.Body>
									<Card.Title className="fs-2">
										Dimension stone is natural rock cut to specific dimensions
										for use in the construction and monument industries-
										granite, marble, bluestone and more
									</Card.Title>
									<Card.Text className="fs-5">
										We offer specialist freight services for the export/import
										of dimension stone blocks and slabs
									</Card.Text>
								</Card.Body>
							</Card>
							<Row xs={1} md={2} className="g-4 my-3">
								<Col>
									<Card className="mx-2 border-0">
										<Card.Img
											className="img-fluid rounded mx-auto d-block w-25"
											variant="top"
											src="https://res.cloudinary.com/dzdj5vlz4/image/upload/v1656417879/FairLogistics-React/container-Planning.jpg"
										/>
										<Card.Title
											className="text-center fw-bold fs-5"
											style={{ color: '#e51d23' }}
										>
											Container Planning
										</Card.Title>
									</Card>
								</Col>
								<Col>
									<Card className="mx-2 border-0">
										<Card.Img
											className="img-fluid rounded mx-auto d-block w-25"
											variant="top"
											src="https://res.cloudinary.com/dzdj5vlz4/image/upload/v1656417981/FairLogistics-React/container-Packing.jpg"
										/>
										<Card.Title
											className="text-center fw-bold fs-5"
											style={{ color: '#e51d23' }}
										>
											Container Packing
										</Card.Title>
									</Card>
								</Col>
								<Col>
									<Card className="mx-2 border-0">
										<Card.Img
											className="img-fluid rounded mx-auto d-block w-25"
											variant="top"
											src="https://res.cloudinary.com/dzdj5vlz4/image/upload/v1656418013/FairLogistics-React/procedural-Compliance.jpg"
										/>
										<Card.Title
											className="text-center fw-bold fs-5"
											style={{ color: '#e51d23' }}
										>
											Procedural Compliance including fumigation
										</Card.Title>
									</Card>
								</Col>
								<Col>
									<Card className="mx-2 border-0">
										<Card.Img
											className="img-fluid rounded mx-auto d-block w-25"
											variant="top"
											src="https://res.cloudinary.com/dzdj5vlz4/image/upload/v1656418041/FairLogistics-React/custom-Documentation.jpg"
										/>
										<Card.Title
											className="text-center fw-bold fs-5"
											style={{ color: '#e51d23' }}
										>
											Custom Documentation
										</Card.Title>
									</Card>
								</Col>
							</Row>
						</Col>
						<Col>
							<EmailFormComponent />
						</Col>
					</Row>
				</Container>
				<FooterComponent />
			</div>
		);
	}
}

export default SpecialistFreightComponent;
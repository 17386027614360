import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Carousel } from 'react-bootstrap';
import '../styles.css';

class CarouselComponent extends React.Component {
	render() {
		return (
			<div className="CarouselComponent">
				<Carousel>
					<Carousel.Item interval={2500}>
						<img
							className="d-block w-100 img-fluid"
							src="https://images.unsplash.com/photo-1523833082115-1e8e294bd14e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1176&q=80"
							alt="First slide"
						/>
						<Carousel.Caption
							className=" align-items-center"
							style={{
								color: 'white',
								margin: '25px',
								backgroundColor: 'black',
								border: '1px solid black',
								opacity: '0.8',
							}}
						>
							<span className="display-3 text_carousel">
								<em>Air Freight</em>
							</span>
						</Carousel.Caption>
					</Carousel.Item>
					<Carousel.Item interval={2500}>
						<img
							className="d-block w-100 img-fluid"
							src="https://images.unsplash.com/photo-1623486175858-81e03b95b677?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1176&q=80"
							alt="Second slide"
						/>

						<Carousel.Caption
							className=" mx-auto"
							style={{
								color: 'white',
								margin: '25px',
								backgroundColor: 'black',
								border: '1px solid black',
								opacity: '0.8',
							}}
						>
							<span className="display-3 text_carousel">
								<em>Sea Freight</em>
							</span>
						</Carousel.Caption>
					</Carousel.Item>
					<Carousel.Item interval={2500}>
						<img
							className="d-block w-100 img-fluid"
							src="https://images.unsplash.com/photo-1627309366653-2dedc084cdf1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1176&q=80"
							alt="Second slide"
						/>

						<Carousel.Caption
							className=" mx-auto"
							style={{
								color: 'white',
								margin: '25px',
								backgroundColor: 'black',
								border: '1px solid black',
								opacity: '0.8',
							}}
						>
							<span className="display-3 text_carousel">
								<em>Specialized Services</em>
							</span>
						</Carousel.Caption>
					</Carousel.Item>
					<Carousel.Item interval={2500}>
						<img
							className="d-block w-100 img-fluid"
							src="https://images.unsplash.com/photo-1561490497-43bc900ac2d8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1176&q=80"
							alt="Second slide"
						/>

						<Carousel.Caption
							className=" mx-auto"
							style={{
								color: 'white',
								margin: '25px',
								backgroundColor: 'black',
								border: '1px solid black',
								opacity: '0.8',
							}}
						>
							<span className="display-3 text_carousel">
								<em>Trade Fair</em>
							</span>
						</Carousel.Caption>
					</Carousel.Item>
				</Carousel>
			</div>
		);
	}
}

export default CarouselComponent;
import React from 'react';
import { collection, addDoc, doc, getDoc, setDoc } from 'firebase/firestore';
import { getAuth, signInAnonymously, onAuthStateChanged } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import '../styles.css';
import 'bootstrap/dist/css/bootstrap.css';
import { db } from '../firebase';

import { Row, Col, Form, Button, Alert, Spinner } from 'react-bootstrap';
import { useState } from 'react';

function EmailFormComponent(props) {
	const [inputs, setInputs] = useState({});
	const [show, setShow] = useState(false);
	const [emailSent, setemailSent] = useState(false);
	const [spinner, SetSpinner] = useState(false);
	const [disabled, SetDisabled] = useState(false);
	const [alertUser, SetAlertUser] = useState(false);

	const handleChange = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		setInputs((values) => ({ ...values, [name]: value }));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		SetSpinner(true);
		SetDisabled(true);

		signInUserAnonymously();
	};

	async function signInUserAnonymously() {
		const auth = await getAuth();
		signInAnonymously(auth)
			.then(getUserDetails())
			.catch((error) => {
				const errorCode = error.code;
				console.log(errorCode);

				const errorMessage = error.message;
				console.log(errorMessage);
			});
	}

	async function getUserDetails() {
		const auth = await getAuth();
		onAuthStateChanged(auth, (user) => {
			if (user) {
				// User is signed in, see docs for a list of available properties
				// https://firebase.google.com/docs/reference/js/firebase.User
				const uidInfo = user.uid;

				console.log('uid ' + uidInfo);

				checkSpam(user);
			} else {
				console.log('No details - user signed out');
			}
		});
	}

	async function checkSpam(user) {
		const d = new Date();
		const a = `${user.uid}+"___"+${inputs.email}`;
		const docRef = doc(db, 'Users', a);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			console.log('Document data:', docSnap.data());
			const userData = docSnap.data();
			const timeDiff = d.getTime() - userData.lastResponseTime;
			console.log('Currrent time' + d.getTime());
			console.log('Last time' + userData.lastResponseTime);
			console.log('timeDiff' + timeDiff);
			var minutes = Math.floor(timeDiff / 60000);
			console.log('minutes ' + minutes);

			if (userData.responseCount > 3 && minutes < 1) {
				console.log('dont spam');
				setInputs('');
				SetSpinner(false);
				SetDisabled(false);
				SetAlertUser(true);
				setShow(true);
			} else {
				updateUserToFirestore(userData);
			}
		} else {
			// doc.data() will be undefined in this case
			console.log('No such document!');
			addUserToFirestore(user);
		}
	}

	async function addUserToFirestore(user) {
		const d = new Date();
		console.log('addUserToFirestore:uid:' + user.uid);
		try {
			await setDoc(doc(db, 'Users', `${user.uid}+"___"+${inputs.email}`), {
				uid: user.uid,
				email: inputs.email,
				responseCount: 1,
				lastResponseTime: d.getTime(),
			});

			addDataToFirestore();
		} catch (e) {
			console.error('Error adding document: ', e);
		}
	}

	async function updateUserToFirestore(userData) {
		const d = new Date();
		try {
			await setDoc(doc(db, 'Users', `${userData.uid}+"___"+${inputs.email}`), {
				uid: userData.uid,
				email: inputs.email,
				responseCount: userData.responseCount + 1,
				lastResponseTime: d.getTime(),
			});

			addDataToFirestore();
		} catch (e) {
			console.error('Error adding document: ', e);
		}
	}

	async function addDataToFirestore() {
		try {
			const docRef = await addDoc(collection(db, `formResponses/Responses/${inputs.email}`), {
				personName: inputs.personName,
				email: inputs.email,
				category: inputs.category,
				subject: inputs.subject,
				message: inputs.message,
			});
			console.log('Document written with ID: ', docRef.id);

			if (docRef.id) {
				console.log('email sending process starting');
				sendEmailToCompany();
			}
		} catch (e) {
			console.error('Error adding document: ', e);
			setemailSent(false);
		}
	}

	async function sendEmailToCompany() {
		console.log('sendEmailToCompany');

		const functions = getFunctions();
		const sendEmail = httpsCallable(functions, 'sendEmail');
		sendEmail({ formInputs: inputs }).then((result) => {
			// Read result of the Cloud Function.
			/** @type {any} */
			const data = result.data;
			console.log('from firease functions' + data);
			SetSpinner(false);
			SetDisabled(false);
			setShow(true);
			setemailSent(true);
			setInputs('');
		});
	}

	// async function sendEmail() {
	// 	window.Email.send({
	// 		SecureToken: '9adb7e6e-5550-4e31-94a8-02259b29951b',
	// 		To: inputs.email,
	// 		From: 'gupta.ayush1997@gmail.com',
	// 		Subject: 'Thanks for contacting Fairlogistic!',
	// 		Body: `Hi ${inputs.personName}! We will reach out to you soon <p>Your query: ${inputs.subject} - ${inputs.message}</p>`,
	// 	}).then((message) => (message === 'OK' ? console.log('abc') : console.log('abc')));
	// }

	return (
		<div className="EmailFormComponent">
			<h4 className="display-6 text-center mx-auto my-auto">Drop us an email</h4>

			<Row xs={1} md={1} className="g-1 w-75 mx-auto m-3">
				<Form onSubmit={handleSubmit}>
					<Col>
						<Form.Group className="mb-3" controlId="formBasicEmail">
							<Form.Label>Name</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter Name"
								name="personName"
								value={inputs.personName || ''}
								onChange={handleChange}
								required
							/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group className="mb-3" controlId="formBasicEmail">
							<Form.Label>Email</Form.Label>
							<Form.Control
								type="email"
								placeholder="Enter Email"
								name="email"
								value={inputs.email || ''}
								required
								onChange={handleChange}
							/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group className="mb-3" controlId="formBasicEmail">
							<Form.Label>Select Category</Form.Label>

							<Form.Select
								required
								name="category"
								value={inputs.category || ''}
								defaultValue="General Logistics"
								onChange={handleChange}
							>
								<option>
									<em>Select category</em>
								</option>
								<option>General Logistics</option>
								<option>Exhibition Logistics</option>
								<option>Exhibition Participation</option>
								<option>Others</option>
							</Form.Select>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group className="mb-3" controlId="formBasicEmail">
							<Form.Label>Subject</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter Subject"
								name="subject"
								value={inputs.subject || ''}
								onChange={handleChange}
								required
							/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group className="mb-3" controlId="formBasicEmail">
							<Form.Label>Message</Form.Label>
							<Form.Control
								as="textarea"
								type="text"
								placeholder="Enter Message"
								required
								name="message"
								value={inputs.message || ''}
								onChange={handleChange}
							/>
						</Form.Group>
					</Col>
					{show ? (
						alertUser ? (
							<Alert variant="danger" onClose={() => setShow(!show)} dismissible>
								<Alert.Heading>You submitted details too many times!</Alert.Heading>

								<strong>
									<p>Kindly try later </p>
								</strong>
							</Alert>
						) : emailSent ? (
							<Alert variant="success" onClose={() => setShow(!show)} dismissible>
								<Alert.Heading>
									Email sent to FairLogistics support team
								</Alert.Heading>

								<strong>
									<p>
										Please view your inbox folder for more details.
									</p>
									<p>
										( Kindly check spam folder if email not received in primay
										inbox )
									</p>
								</strong>
							</Alert>
						) : (
							<Alert variant="danger" onClose={() => setShow(!show)} dismissible>
								<Alert.Heading>Query not submitted</Alert.Heading>

								<strong>
									<p>Kindly try again </p>
								</strong>
							</Alert>
						)
					) : (
						''
					)}

					<Button
						style={{ backgroundColor: '#2C3180' }}
						type="submit"
						disabled={disabled}
					>
						{spinner ? (
							<span>
								<Spinner
									as="span"
									animation="border"
									size="sm"
									role="status"
									aria-hidden="true"
								/>{' '}
								Submitting...
							</span>
						) : (
							'Submit'
						)}
					</Button>
				</Form>
			</Row>
		</div>
	);
}

export default EmailFormComponent;
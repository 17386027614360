import React from 'react';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import { Modal } from 'react-bootstrap';
import NavbarComponent from './Navbar';
import FooterComponent from './footer';

export default class TradeCalendarComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			events: [
				{
					title: 'event 1',
					start: '2022-09-27',
					end: '2022-09-31',
					desc: 'This is description for event 1',
					color: 'Orange'
				},
				{
					title: 'event 2',
					date: '2022-07-15',
					desc: 'This is description for event 2',
				},
			],
			filteredEvent: [],
			show: false,
		};
	}

	handleEventClick = (clickInfo) => {
		this.state.events
			.filter((event) => event.title.includes(clickInfo.event.title))
			.map((filteredEvent) => this.setState({ filteredEvent: filteredEvent, show: true }));
	};

	render() {
		return (
			<div className="TradeCalendarComponent">
				<NavbarComponent />

				<Modal
					centered
					show={this.state.show}
					onClick={() => this.setState({ show: false })}
				>
					<Modal.Header closeButton>
						<Modal.Title>{this.state.filteredEvent.title}</Modal.Title>
					</Modal.Header>
					<Modal.Body>{this.state.filteredEvent.desc}</Modal.Body>
				</Modal>
				<h4 className="display-6 mb-5 text-center mt-5">Company Trade Fair Calendar</h4>

				<div className="calendar">
					<FullCalendar
						plugins={[dayGridPlugin, timeGridPlugin]}
						headerToolbar={{
							left: 'prev,next today',
							center: 'title',
							right: 'dayGridMonth,timeGridWeek,timeGridDay',
						}}
						initialView="dayGridMonth"
						weekends="true"
						events={this.state.events}
						eventClick={this.handleEventClick}
					/>
				</div>

				<FooterComponent />
			</div>
		);
	}
}
import React from 'react';
import '../styles.css';
import 'bootstrap/dist/css/bootstrap.css';
import NavbarComponent from './Navbar';
import FooterComponent from './footer';

import EmailFormComponent from './EmailForm';
import { Row, Col, Container, Card } from 'react-bootstrap';

class SeaFreightComponent extends React.Component {
	render() {
		return (
			<div className="SeaFreightComponent">
				<NavbarComponent />
				<Container>
					<Row xs={1} md={2} className="g-4 my-5">
						<Col>
							<Card
								text="light"
								className="text-center p-3 border-0 rounded"
								style={{ backgroundColor: '#2C3180' }}
							>
								<Card.Body>
									<Card.Title className="fs-2">
										Sea freight is the choice for many businesses looking for a
										cost-effective solution
									</Card.Title>
									<Card.Text className="fs-5">
										We offer our customers a comprehensive range of ocean
										freight services. The solutions include the following
									</Card.Text>
								</Card.Body>
							</Card>
							<Row xs={1} md={2} className="g-4 my-3">
								<Col>
									<Card className="mx-2 border-0">
										<Card.Img
											className="img-fluid rounded mx-auto d-block w-25"
											variant="top"
											src="https://res.cloudinary.com/dzdj5vlz4/image/upload/v1656416669/FairLogistics-React/export-and-Import.jpg"
										/>
										<Card.Title
											className="text-center fw-bold fs-5"
											style={{ color: '#e51d23' }}
										>
											Export and Import Services
										</Card.Title>
									</Card>
								</Col>
								<Col>
									<Card className="mx-2 border-0">
										<Card.Img
											className="img-fluid rounded mx-auto d-block w-25"
											variant="top"
											src="https://res.cloudinary.com/dzdj5vlz4/image/upload/v1656416751/FairLogistics-React/LCL.jpg"
										/>
										<Card.Title
											className="text-center fw-bold fs-5"
											style={{ color: '#e51d23' }}
										>
											LCL (Less than a Container Load)
										</Card.Title>
									</Card>
								</Col>
								<Col>
									<Card className="mx-2 border-0">
										<Card.Img
											className="img-fluid rounded mx-auto d-block w-25"
											variant="top"
											src="https://res.cloudinary.com/dzdj5vlz4/image/upload/v1656416781/FairLogistics-React/FCL.jpg"
										/>
										<Card.Title
											className="text-center fw-bold fs-5"
											style={{ color: '#e51d23' }}
										>
											FCL (Full Container Load)
										</Card.Title>
									</Card>
								</Col>
								<Col>
									<Card className="mx-2 border-0">
										<Card.Img
											className="img-fluid rounded mx-auto d-block w-25"
											variant="top"
											src="https://res.cloudinary.com/dzdj5vlz4/image/upload/v1656416808/FairLogistics-React/full-Documentation.jpg"
										/>
										<Card.Title
											className="text-center fw-bold fs-5"
											style={{ color: '#e51d23' }}
										>
											Full Documentation
										</Card.Title>
									</Card>
								</Col>
								<Col>
									<Card className="mx-2 border-0">
										<Card.Img
											className="img-fluid rounded mx-auto d-block w-25"
											variant="top"
											src="https://res.cloudinary.com/dzdj5vlz4/image/upload/v1656416870/FairLogistics-React/port-to-Port.jpg"
										/>
										<Card.Title
											className="text-center fw-bold fs-5"
											style={{ color: '#e51d23' }}
										>
											Port-to-Port/Door-to-door
										</Card.Title>
									</Card>
								</Col>
								<Col>
									<Card className="mx-2 border-0">
										<Card.Img
											className="img-fluid rounded mx-auto d-block w-25"
											variant="top"
											src="https://res.cloudinary.com/dzdj5vlz4/image/upload/v1656416907/FairLogistics-React/customs-Clearance.jpg"
										/>
										<Card.Title
											className="text-center fw-bold fs-5"
											style={{ color: '#e51d23' }}
										>
											Custom clearance at the country of origin/the port of
											discharge
										</Card.Title>
									</Card>
								</Col>
							</Row>
						</Col>
						<Col>
							<EmailFormComponent />
						</Col>
					</Row>
				</Container>
				<FooterComponent />
			</div>
		);
	}
}

export default SeaFreightComponent;
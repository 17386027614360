import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Card, Container, Row, Col } from 'react-bootstrap';
import NavbarComponent from './Navbar';
import FooterComponent from './footer';

class AboutComponent extends React.Component {
	render() {
		return (
			<div className="AboutComponent">
				<NavbarComponent />
				<Container>
					<Card
						text="light"
						className="my-5 text-center p-3 border-0 rounded"
						style={{ backgroundColor: '#2C3180' }}
					>
						<Card.Body>
							<Card.Title className="fs-2">Vision and Mission</Card.Title>
							<Card.Text className="fs-5">
								Fair Logistics is a specialist NVOCC and Freight Forwarding company
								that offers tailor-made services to meet our customers’ specific
								requirements
							</Card.Text>
						</Card.Body>
					</Card>
					<Row xs={1} md={2} className="g-4 my-3">
						<Col>
							<Card className="mx-2 border-0">
								<Card.Img
									className="img-fluid rounded mx-auto d-block w-25"
									variant="top"
									src="https://res.cloudinary.com/dzdj5vlz4/image/upload/v1656163482/FairLogistics-React/vision.png"
								/>
								<Card.Body>
									<Card.Text className="fs-5 text-center">
										Be the supply chain partner that our customers love, trust
										and recommend and to be the successful organization our
										employees are proud of
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
						<Col>
							<Card className="mx-2 border-0">
								<Card.Img
									className="img-fluid rounded mx-auto d-block w-25 "
									variant="top"
									src="https://res.cloudinary.com/dzdj5vlz4/image/upload/v1656163547/FairLogistics-React/mission.png"
								/>
								<Card.Body>
									<Card.Text className="fs-5 text-center">
										Provide exceptional service through right freight solutions,
										thorough industry knowledge, warm but open communication,
										great network and zero surprise operations
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
						<Col>
							<Card className="mx-2 border-0">
								<Card.Img
									className="img-fluid rounded mx-auto d-block w-25"
									variant="top"
									src="https://res.cloudinary.com/dzdj5vlz4/image/upload/v1656163603/FairLogistics-React/trans.png"
								/>
								<Card.Body>
									<Card.Text className="fs-5 text-center">
										We have strong relationships with multiple ocean, air, and
										land carriers so you get a superior scheduling reliability
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
						<Col>
							<Card className="mx-2 border-0">
								<Card.Img
									className="img-fluid rounded mx-auto d-block w-25"
									variant="top"
									src="https://res.cloudinary.com/dzdj5vlz4/image/upload/v1656163662/FairLogistics-React/icon2.png"
								/>
								<Card.Body>
									<Card.Text className="fs-5 text-center">
										With Fair Logistics, you get a superior service at a
										competitive pricing on ocean, air, and surface transport
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
						<Col>
							<Card className="mx-2 border-0">
								<Card.Img
									className="img-fluid rounded mx-auto d-block w-25"
									variant="top"
									src="https://res.cloudinary.com/dzdj5vlz4/image/upload/v1656163716/FairLogistics-React/tradefair.png"
								/>
								<Card.Body>
									<Card.Text className="fs-5 text-center">
										We have the industry gold standard services for trade fair &
										exhibition logistics all over the world
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
						<Col>
							<Card className="mx-2 border-0">
								<Card.Img
									className="img-fluid rounded mx-auto d-block w-25"
									variant="top"
									src="https://res.cloudinary.com/dzdj5vlz4/image/upload/v1656163756/FairLogistics-React/icon4.png"
								/>
								<Card.Body>
									<Card.Text className="fs-5 text-center">
										We have the ability to ship by any mode of transport to any
										destination in the world through a network of associates
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
						<Col>
							<Card className="mx-2 border-0">
								<Card.Img
									className="img-fluid rounded mx-auto d-block w-25"
									variant="top"
									src="https://res.cloudinary.com/dzdj5vlz4/image/upload/v1656163815/FairLogistics-React/AllItems.png"
								/>
								<Card.Body>
									<Card.Text className="fs-5 text-center">
										We can handle any type of cargo, including oversized
										dimension stone blocks, clothing, perishables food,
										chemicals, hazardous materials, high-value cargo, sensitive
										instruments, pharmaceuticals and heavy machinery
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
						<Col>
							<Card className="mx-2 border-0">
								<Card.Img
									className="img-fluid rounded mx-auto d-block w-25"
									variant="top"
									src="https://res.cloudinary.com/dzdj5vlz4/image/upload/v1656163852/FairLogistics-React/cargo.png"
								/>
								<Card.Body>
									<Card.Text className="fs-5 text-center">
										We can provide third-party bonded warehousing in any port
										area for any type of cargo
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
				<FooterComponent />
			</div>
		);
	}
}

export default AboutComponent;
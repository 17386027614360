// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
 import { getFunctions } from 'firebase/functions';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: 'AIzaSyB0GDKkSibyx4RTJcHybMnjoxWY5g6JOyE',
	authDomain: 'fairlogistic-8f369.firebaseapp.com',
	projectId: 'fairlogistic-8f369',
	storageBucket: 'fairlogistic-8f369.appspot.com',
	messagingSenderId: '898053484486',
	appId: '1:898053484486:web:7a5a7a01cbf012e8c00cc7',
	measurementId: 'G-LE4E16L12T',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const  db = getFirestore(app);

export const auth = getAuth(app);

const functions = getFunctions(app);
import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Nav, Navbar, NavDropdown, Container } from 'react-bootstrap';
import '../styles.css';
import { Link } from 'react-router-dom';

class NavbarComponent extends React.Component {
	constructor(props) {
		super(props);
		this.activateCenterDropdown = this.activateCenterDropdown.bind(this);
	}

	activateCenterDropdown() {}

	render() {
		return (
			<div className="NavbarComponent">
				<Navbar
					bg="light"
					variant="light"
					expand="lg"
					collapseOnSelect
					style={{ backgroundColor: '#EEEFF1', zIndex: '1' }}
				>
					<Container>
						<Navbar.Brand className="display-1 fs-2">
							<Link to="/">
								<img
									src="https://res.cloudinary.com/dzdj5vlz4/image/upload/v1655976104/FairLogistics-React/logo-stright-200-removebg-preview.png"
									width="200px"
									height="80px"
									alt=""
								/>
							</Link>
						</Navbar.Brand>

						<Navbar.Toggle />
						<Navbar.Collapse>
							<Nav className="ms-auto mb-2 mb-lg-0">
								<span className="nav-item-underline mx-3">
									<Nav.Link>
										<Link
											style={{ textDecoration: 'none' }}
											id="text-red"
											to="/about"
										>
											About Us
										</Link>
									</Nav.Link>
								</span>
								<span
									className=" mx-3"
									onClick={this.activateCenterDropdown}
								>
									<NavDropdown id="text-red" title="Our Services" >
										
										<NavDropdown.Item href="/airfreight" className="nav-item-underline"  >
											<Link
											  style={{ textDecoration:"none"}}
												id="text-red"
												to="/airfreight"
											>
												Air Freight
											</Link>
										</NavDropdown.Item>

										<NavDropdown.Item href="/seafreight" className="nav-item-underline">
											<Link
												style={{ textDecoration: 'none' }}
												id="text-red"
												to="/seafreight"
											>
												Sea Freight
											</Link>
										</NavDropdown.Item>
										<NavDropdown.Item href="/specialistfreight" className="nav-item-underline">
											<Link
												style={{ textDecoration: 'none' }}
												id="text-red"
												to="/specialistfreight"
											>
												Specialized Services
											</Link>
										</NavDropdown.Item>
										<NavDropdown.Item id="text-red" href="/tradecalendar" className="nav-item-underline">
											<Link
												style={{ textDecoration: 'none' }}
												id="text-red"
												to="/tradecalendar"
											>
												Trade Fair Show Calendar
											</Link>
										</NavDropdown.Item>
										
									</NavDropdown>
								</span>
								<span className="nav-item-underline mx-3">
									<Nav.Link>
										<Link
											style={{ textDecoration: 'none' }}
											id="text-red"
											to="/contact"
										>
											Contact Us
										</Link>
									</Nav.Link>
								</span>
							</Nav>
						</Navbar.Collapse>
					</Container>
				</Navbar>
			</div>
		);
	}
}

export default NavbarComponent;
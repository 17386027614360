import React from 'react';
import '../styles.css';
import 'bootstrap/dist/css/bootstrap.css';
import NavbarComponent from './Navbar';
import FooterComponent from './footer';

import EmailFormComponent from './EmailForm';
import { Row, Col, Container, Card } from 'react-bootstrap';

class AirFreightComponent extends React.Component {
	render() {
		return (
			<div className="AirFreightComponent">
				<NavbarComponent />
				<Container>
					<Row xs={1} md={2} className="g-4 my-5">
						<Col>
							<Card
								text="light"
								className="text-center p-3 border-0 rounded"
								style={{ backgroundColor: '#2C3180' }}
							>
								<Card.Body>
									<Card.Title className="fs-2">
										Safe, flexible and reliable - favored for time-sensitive
										shipments
									</Card.Title>
									<Card.Text className="fs-5">
										Our air fright solutions have the following key offerings
									</Card.Text>
								</Card.Body>
							</Card>
							<Row xs={1} md={2} className="g-4 my-3">
								<Col>
									<Card className="mx-2 border-0">
										<Card.Img
											className="img-fluid rounded mx-auto d-block w-25"
											variant="top"
											src="https://res.cloudinary.com/dzdj5vlz4/image/upload/v1656310562/FairLogistics-React/direct-Airfreight.jpg"
										/>
										<Card.Title
											className="text-center fw-bold fs-5"
											style={{ color: '#e51d23' }}
										>
											Direct Airfreight
										</Card.Title>
									</Card>
								</Col>
								<Col>
									<Card className="mx-2 border-0">
										<Card.Img
											className="img-fluid rounded mx-auto d-block w-25"
											variant="top"
											src="https://res.cloudinary.com/dzdj5vlz4/image/upload/v1656310624/FairLogistics-React/consolidated-Airfreight.jpg"
										/>
										<Card.Title
											className="text-center fw-bold fs-5"
											style={{ color: '#e51d23' }}
										>
											Consolidated Airfreight
										</Card.Title>
									</Card>
								</Col>
								<Col>
									<Card className="mx-2 border-0">
										<Card.Img
											className="img-fluid rounded mx-auto d-block w-25"
											variant="top"
											src="https://res.cloudinary.com/dzdj5vlz4/image/upload/v1656310699/FairLogistics-React/airport-to-Airport.jpg"
										/>
										<Card.Title
											className="text-center fw-bold fs-5"
											style={{ color: '#e51d23' }}
										>
											Airport to Airport
										</Card.Title>
									</Card>
								</Col>
								<Col>
									<Card className="mx-2 border-0">
										<Card.Img
											className="img-fluid rounded mx-auto d-block w-25"
											variant="top"
											src="https://res.cloudinary.com/dzdj5vlz4/image/upload/v1656310745/FairLogistics-React/Door-to-Door.jpg"
										/>
										<Card.Title
											className="text-center fw-bold fs-5"
											style={{ color: '#e51d23' }}
										>
											Door to Door
										</Card.Title>
									</Card>
								</Col>
								<Col>
									<Card className="mx-2 border-0">
										<Card.Img
											className="img-fluid rounded mx-auto d-block w-25"
											variant="top"
											src="https://res.cloudinary.com/dzdj5vlz4/image/upload/v1656310782/FairLogistics-React/express.jpg"
										/>
										<Card.Title
											className="text-center fw-bold fs-5"
											style={{ color: '#e51d23' }}
										>
											Express
										</Card.Title>
									</Card>
								</Col>
								<Col>
									<Card className="mx-2 border-0">
										<Card.Img
											className="img-fluid rounded mx-auto d-block w-25"
											variant="top"
											src="https://res.cloudinary.com/dzdj5vlz4/image/upload/v1656310832/FairLogistics-React/custom-clearance.jpg"
										/>
										<Card.Title
											className="text-center fw-bold fs-5"
											style={{ color: '#e51d23' }}
										>
											Custom clearance at the country of origin/the port of
											discharge
										</Card.Title>
									</Card>
								</Col>
								<Col>
									<Card className="mx-2 border-0">
										<Card.Img
											className="img-fluid rounded mx-auto d-block w-25"
											variant="top"
											src="https://res.cloudinary.com/dzdj5vlz4/image/upload/v1656310909/FairLogistics-React/relief-Goods.jpg"
										/>
										<Card.Title
											className="text-center fw-bold fs-5"
											style={{ color: '#e51d23' }}
										>
											Relief Goods and Humanitarian Aid Projects
										</Card.Title>
									</Card>
								</Col>
								<Col>
									<Card className="mx-2 border-0">
										<Card.Img
											className="img-fluid rounded mx-auto d-block w-25"
											variant="top"
											src="https://res.cloudinary.com/dzdj5vlz4/image/upload/v1656310951/FairLogistics-React/perishable.jpg"
										/>
										<Card.Title
											className="text-center fw-bold fs-5"
											style={{ color: '#e51d23' }}
										>
											Perishable Cargo
										</Card.Title>
									</Card>
								</Col>
							</Row>
						</Col>
						<Col>
							<EmailFormComponent />
						</Col>
					</Row>
				</Container>
				<FooterComponent />
			</div>
		);
	}
}

export default AirFreightComponent;
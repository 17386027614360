import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container } from 'react-bootstrap';

import '../styles.css';

class FooterComponent extends React.Component {
	render() {
		return (
			<div className="FooterComponent">
				<div
					className="text-center text-md-left p-3"
					style={{ backgroundColor: '#FFDBA4', color: 'black' }}
				>
					<Container fluid>
						<div className="row">
							<div className="col-md-4 my-auto">
								<h3>
									<span style={{ color: 'blue' }}> Fair</span>
									<span style={{ color: '#e51d23' }}> Logistics</span>
								</h3>
							</div>

							<hr className="clearfix w-100 d-md-none pb-0" />

							<div className="col-md-4 my-auto">
								<h5 className="">Contact Us</h5>
								<ul className="list-unstyled">
									<li>
										<span href="#!">
											Fair Logistics - India (+91 98185 43617 )
										</span>
									</li>
									<li>
										<span href="#!">
											Fair Logistics - Germany (+49 1516 8199812)
										</span>
									</li>
									<li>
										<span href="#!">
											Fair Logistics - North America (+1 212-221-7654)
										</span>
									</li>
									<li>
										<span href="#!">
											Fair Logistics - UK (+44 7502-200813 )
										</span>
									</li>
								</ul>
							</div>

							<div className="col-md-4 my-auto">
								<h5 className="">Social Media</h5>
								<ul className="list-unstyled">
									<li>
										<a href="#!">Facebook</a>
									</li>
									<li>
										<a href="#!">Linkedin</a>
									</li>
									<li>
										<a href="#!">instagram</a>
									</li>
									<li>
										<a href="#!">Twitter</a>
									</li>
								</ul>
							</div>
						</div>
					</Container>
				</div>
				<div
					style={{ backgroundColor: '#FFDBA4', color: 'black' }}
					className="footer-copyright text-center py-3"
				>
					Copyright @ 2022
					<span style={{ color: 'blue' }}> Fair</span>
					<span style={{ color: '#e51d23' }}> Logistics</span>
				</div>
			</div>
		);
	}
}

export default FooterComponent;
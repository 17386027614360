import React from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './styles.css';
import 'bootstrap/dist/css/bootstrap.css';
import HomeComponent from './Home';
import ContactComponent from './Components/Contact';
import AboutComponent from './Components/About';
import AirFreightComponent from './Components/AirFreight';
import SeaFreightComponent from './Components/SeaFreight';
import SpecialistFreightComponent from './Components/SpecialistFreight';
import TradeCalendarComponent from './Components/tradeCalendar';

class App extends React.Component {
	render() {
		return (
			<Router>
				<div className="App">
					<Routes>
						<Route exact path="/" element={<HomeComponent />} />

						<Route path="/about" element={<AboutComponent />} />

						<Route path="/contact" element={<ContactComponent />} />

						<Route path="/airfreight" element={<AirFreightComponent />} />

						<Route path="/seafreight" element={<SeaFreightComponent />} />

						<Route path="/specialistfreight" element={<SpecialistFreightComponent />} />

						<Route path="/tradecalendar" element={<TradeCalendarComponent />} />
					</Routes>
				</div>
			</Router>
		);
	}
}

export default App;
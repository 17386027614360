import React from 'react';
import '../styles.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Card, Container, Row, Col } from 'react-bootstrap';

class ContentComponent extends React.Component {
	render() {
		return (
			<div className="ContentComponent">
				<Container className="my-3">
					<Card
						text="light"
						className="my-5 text-center p-3 border-0"
						style={{ backgroundColor: '#2C3180' }}
					>
						<Card.Body>
							<Card.Title className="fs-2">
								The Eyes and Ears of your Supply Chain
							</Card.Title>
							<Card.Text className="fs-5">
								We help you deliver value to your customers. We also help you with insights
								from your supply chain to save cost and serve customers even better
							</Card.Text>
						</Card.Body>
					</Card>
					<h4 className="display-6 mb-5 text-center">Our Specialities</h4>
					<Row xs={1} md={2} className="g-4">
						<Col>
							<Card className="mx-2 border-0">
								<Card.Img
									className="img-fluid rounded mx-auto d-block w-25"
									variant="top"
									src="https://res.cloudinary.com/dzdj5vlz4/image/upload/v1655975923/FairLogistics-React/h1.jpg"
								/>
								<Card.Body>
									<Card.Title
										className="text-center fw-bold fs-4"
										style={{ color: '#e51d23' }}
									>
										Global and Local Reach
									</Card.Title>
									<Card.Text className="fs-5 text-center">
										We deliver to all destinations from sub polar regions to
										distant islands, both globally and locally. Import and
										export, door to door DDU/DDP, CIF and freight collect
										services utilising our vast network of agents on all the
										continents
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
						<Col>
							<Card className="mx-2 border-0">
								<Card.Img
									className="img-fluid rounded mx-auto d-block w-25"
									variant="top"
									src="https://res.cloudinary.com/dzdj5vlz4/image/upload/v1655975924/FairLogistics-React/h2.jpg"
								/>
								<Card.Body>
									<Card.Title
										className="text-center fw-bold fs-4"
										style={{ color: '#e51d23' }}
									>
										We are a learning organization
									</Card.Title>
									<Card.Text className="fs-5 text-center">
										We understand customs rules and regulations of every country
										through which your shipment will pass. We have the knowledge
										& demonstrated ability to manage transportation, risk, and
										costs
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
						<Col>
							<Card className="mx-2 border-0">
								<Card.Img
									className="img-fluid rounded mx-auto d-block w-25"
									variant="top"
									src="https://res.cloudinary.com/dzdj5vlz4/image/upload/v1655975923/FairLogistics-React/h3.jpg"
								/>
								<Card.Body>
									<Card.Title
										className="text-center fw-bold fs-4"
										style={{ color: '#e51d23' }}
									>
										We listen to you
									</Card.Title>
									<Card.Text className="fs-5 text-center">
										We pay attention to your requests and take care of speacial needs
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
						<Col>
							<Card className="mx-2 border-0">
								<Card.Img
									className="img-fluid rounded mx-auto d-block w-25"
									variant="top"
									src="https://res.cloudinary.com/dzdj5vlz4/image/upload/v1655975923/FairLogistics-React/h4.jpg"
								/>
								<Card.Body>
									<Card.Title
										className="text-center fw-bold fs-4"
										style={{ color: '#e51d23' }}
									>
										We act as your eyes and ears of your supply chain
									</Card.Title>
									<Card.Text className="fs-5 text-center">
										We don’t believe our job is done if we deliver your shipment
										on time. We also deliver addition value through our people,
										processes and technology
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default ContentComponent;
import React from 'react';
import '../styles.css';
import 'bootstrap/dist/css/bootstrap.css';
import NavbarComponent from './Navbar';
import FooterComponent from './footer';
import EmailFormComponent from './EmailForm';
import { Card, Container, Row, Col } from 'react-bootstrap';

class ContactComponent extends React.Component {
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSubmit(e) {
		e.preventDefault();
	}

	render() {
		return (
			<div className="ContactComponent">
				<NavbarComponent />
				<Container>
					<h4 className="display-6 mb-5 text-center mt-5">Company Contacts</h4>
					<Row xs={1} md={2} className="g-4">
						<Col>
							<Card className="mx-2 border-0">
								<Card.Body>
									<Card.Title
										className="text-center fw-bold fs-4"
										style={{ color: '#e51d23' }}
									>
										Fair Logistics - India
									</Card.Title>
									<Card.Text className="fs-5 text-center">
										A-180, Second Floor, Street No 10, Road No 4, Mahipalpur, 
										 New Delhi, India, 110037
										<p className="text-muted">
											Phone: <a href="tel:91 98185 43617">+91 98185 43617</a>
										</p>
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
						<Col>
							<Card className="mx-2 border-0">
								<Card.Body>
									<Card.Title
										className="text-center fw-bold fs-4"
										style={{ color: '#e51d23' }}
									>
										Fair Logistics - North America
									</Card.Title>
									<Card.Text className="fs-5 text-center">
										2W 46th St Suite 1201, New York , NY 10036-4532, United
										States
										<p className="text-muted">
											Phone: <a href="tel:1 212-221-7654">+1 212-221-7654</a>
										</p>
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
						<Col>
							<Card className="mx-2 border-0">
								<Card.Body>
									<Card.Title
										className="text-center fw-bold fs-4"
										style={{ color: '#e51d23' }}
									>
										Fair Logistics - UK
									</Card.Title>
									<Card.Text className="fs-5 text-center">
										No. 4 Yardmaster House, 11 Cross Road, Croydon CR06FB,
										United Kingdom
										<p className="text-muted">
											Phone:{' '}
											<a href="tel:44 7502-200813 "> +44 7502-200813 </a>
										</p>
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
						<Col>
							<Card className="mx-2 border-0">
								<Card.Body>
									<Card.Title
										className="text-center fw-bold fs-4"
										style={{ color: '#e51d23' }}
									>
										Fair Logistics - Germany
									</Card.Title>
									<Card.Text className="fs-5 text-center">
										Oskar-Maria-Graf-Ring 35, 81737 München (Munich), Deutschland
										(Germany)
										<p className="text-muted">
											Phone:{' '}
											<a href="tel:49 1516 8199812"> +49 1516 8199812</a>
										</p>
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
					</Row>
					<hr class="w-100" />
					<EmailFormComponent />
				</Container>
				<FooterComponent />
			</div>
		);
	}
}

export default ContactComponent;